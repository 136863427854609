import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  root: {
    padding: '8px 16px',
    marginLeft: 20,
    display: 'flex',
    alignItems: 'center',
    width: 120,
    height: '30px',
  },
  textContainer: {
    margin: 0,
    width: '100%',
    fontSize: 9,
  },
  listItemText: {
    fontSize: 9,
  },
}))

export default styles
