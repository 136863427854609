import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    '& h2': {
      color: theme.white,
      fontWeight: 'normal',
      fontSize: '32px',
      margin: '0 0 20px 0',
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '51px 0px',
    position: 'fixed',
    top: '120px',
    width: 'calc(100% - 215px)',
    height: '100%',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    padding: '40px 0 0 0',
    zIndex: 1200,
    background: theme.primaryColor,
    width: 'calc(100% - 215px)',
  },
  headingItem: {
    flex: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 'bold',
    color: theme.primaryText
  },
  rowItemLeft: {
    display: 'flex',
    maxWidth: '750px',
    margin: '30px 0',
    padding: '0 30px',
    boxSizing: 'border-box',
    overflow: 'initial !important',
    background: theme.offWhite,

    border: `1px solid ${theme.offWhite}`
  },
  rowItemRight: {
    display: 'flex',
    maxWidth: '750px',
    margin: '30px 0',
    padding: '0 30px',
    boxSizing: 'border-box',
    overflow: 'initial !important',
    background: theme.offWhite,

    marginLeft: 'auto',
    border: `1px solid ${theme.tertiaryTextColor}`
  },
  historyContent: {
    paddingLeft: '80px',
    paddingRight: '80px',
    overflowY: 'scroll',
    marginBottom: '160px',
    scrollBehavior: 'smooth'
  },
  heading: {
    padding: '30px 20px 20px 20px',
    fontSize: '20px',
    borderBottom: `1px solid ${theme.primaryColor}`,
    margin: '0 auto',
    width: 'calc(100% - 5px)',
    color: theme.primaryText,
  },
  emptyResult: {
    margin: theme.spacing(4),
    color: theme.primaryText,
    height: '40px',
    alignSelf: 'center'
  },
  successIcon: {
    marginTop: '-16px',
    position: 'relative',
    fontSize: '2em',
    color: theme.healthy,
  },
  errorIcon: {
    marginTop: '-16px',
    position: 'relative',
    fontSize: '2em',
    color: theme.rebuild,
  },
  waitingIcon: {
    marginTop: '-16px',
    position: 'relative',
    fontSize: '2em',
    color: theme.fastService,
  },
  cardMainTitle: {
    fontSize: '14px',
    color: theme.secondaryTextColor,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  cardTitle: {
    fontSize: '14px',
    color: theme.secondaryTextColor,
    fontWeight: 'bold'
  },
  cardTitleTooltip_Tooltip: {
    backgroundColor: theme.primaryColor,
    color: 'white',
    fontSize: 11,
  },
  cardTitleTooltip_Arrow: {
    color: theme.primaryColor,
  },
  cardTitleTooltip_Text: {
    lineHeight: '200%',
    fontSize: 14,
  },
  cardText: {
    fontSize: '14px',
    color: theme.tertiaryTextColor,
    marginTop: '10px'
  },
  cardContentBody: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 16px !important'
  },
  cardFileDownloadContainer: {
    alignSelf: 'flex-end'
  },
  cardMessageContainer: {
    flex: 1.5
  },
  downloadContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.secondaryTextColor,
    alignItems: 'flex-end',
    '& span': {
      fontSize: '12px',
      marginBottom: '10px'
    }
  },
  downloadItem: {
    display: 'flex',
    color: theme.secondaryTextColor,
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& a': {
      marginLeft: '15px',
      textDecoration: 'none',
      fontSize: '14px',
      fontWeight: 'bold',
      color: theme.secondaryTextColor,
      '&:hover': {
        textDecoration: 'underline',
      }
    }
  },
  cardDownloadContainer: {
    padding: '0px 16px !important'
  },
  userAvatar: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    marginTop: '24px',
    alignSelf: 'baseline'
  },
  cardContentHeading: {
    display: 'flex',
  },
  paginationContainer: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    color: theme.primaryText,
    fontSize: '12px',
    margin: '30px 0'
  },
  cardContentContainer: {
    width: '100%'
  },
  defaultLineCard: {
    height: '50px',
    position: 'relative',
    marginTop: '-30px',
    marginRight: '-13px',
    zIndex: -5
  },
  lineCard: {
    border: `1px solid ${theme.offWhite} !important`
  },
  newMessageCard: {
    border: `2px solid ${theme.primaryColor} !important`
  },
  textField: {
    margin: '0 !important',
    width: '100%',
    background: theme.white
  },
  textFieldFocus: {
    color: `${theme.tertiaryTextColor} !important`
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.primaryColor} !important`,
      borderWidth: '1px  !important',
    },
    '&::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder': {
      fontSize: '14px !important',
    },
    color: `${theme.tertiaryTextColor} !important`,
    fontSize: '14px !important',
  },
  cssFocused: {
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: `${theme.tertiaryTextColor} !important`
  },
  buttonIcon: {
    marginRight: '10px',
    fontSize: '16px'
  },
  cardContentButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    padding: '16px 16px 0 16px !important',
    '& button': {
      '&:first-child': {
        marginRight: '25px'
      }
    }
  },
  buttonContainerLink: {
    display: 'flex',
    color: theme.primaryColor,
    textDecoration: 'none',
    marginRight: '25px'
  },
  expansionPanel: {
    width: 'calc(100% - 60px)',
    boxShadow: 'none',
  },
  expansionPanelContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  expansionPanelContentLeft: {
    flex: 1
  },
  expansionPanelContentRight: {
    fontSize: '14px',
    color: theme.primaryColor,
  },
  expansionPanelIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  expansionPanelDetails: {
    fontSize: '12px',
    marginLeft: '25px',
    flexWrap: 'wrap',
    '& b': {
      color: theme.primaryColor
    },
    '& div': {
      margin: '5px 25px'
    }
  }
}))

export default styles
