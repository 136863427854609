import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  listMenus: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeMenu: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60px',
    boxSizing: 'border-box',
    '-moz-box-sizing': 'border-box',
    '-webkit-box-sizing': 'border-box',
    background: 'rgba(0, 0, 0, 0.1)',
    borderLeft: '3px solid #fff',
    '& svg': {
      marginLeft: '-3px'
    }
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 9999,
    margin: '0 30px',
    padding: '0 0 25px 0',
  },
  imageProfile: {
    width: '80px',
    height: '80px',
    border: '4px solid #fff',
    borderRadius: '50%'
  },
  profileContainer: {
    padding: '25px 0 10px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  userData: {
    padding: 0,
    color: theme.white,
    fontWeight: 'bold',
    marginTop: '20px',
    textAlign: 'center'
  },
  userEmail: {
    padding: 0,
    color: theme.white,
    fontSize: '10px',
    marginTop: '10px',
    textAlign: 'center'
  },
  logoImg: {
    maxWidth: '100px',
    marginTop: '5px',
    marginLeft: '20px',
    marginRight: '20px'
  },
  defaultIcon: {
    fontSize: '1.8em !important',
    marginBottom: '10px',
    color: theme.tertiaryTextColor
  },
  disabledIcon: {
    fontSize: '1.8em !important',
    marginBottom: '10px',
    color: theme.disabledTextColor
  },
  defaultIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '11px',
    alignItems: 'center',
    marginBottom: '30px',
    textDecoration: 'none',
    color: theme.tertiaryTextColor,
    '&:last-child': {
      marginBottom: 0
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  disabledIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '11px',
    alignItems: 'center',
    marginBottom: '30px',
    textDecoration: 'none',
    color: theme.disabledTextColor,
    '&:last-child': {
      marginBottom: 0
    }
  },
  menuContainer: {
    marginTop: '35px'
  }
}))

export default styles
