import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  root: {
  },
  imageProfile: {
    width: '150px',
    height: '150px',
    border: '4px solid #fff',
    borderRadius: '50%'
  },
  infosContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '450px',
  },
  passwordContainer: {
    minWidth: '450px',
  },
  passwordInputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  label: {
    fontWeight: '600',
    color: theme.primaryText,
    fontSize: '1vw',
    marginBottom: '15px'
  },
  textField: {
    minWidth: 'calc(25vw - 30px)',
    height: '32px',
    padding: '0 15px',
    border: '1px solid #CCCCCC',
    borderRadius: '4px',
    outlineColor: '#3f51b5',
    outlineWidth: '1px',
  },
}))

export default styles
