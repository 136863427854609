import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
    root: {
        margin: `auto ${theme.spacing(4)}px`,
        color: theme.fastService,
        alignSelf: 'center',
    },
}))

export default styles
