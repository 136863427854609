import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import React from 'react'
import StepLabel from '@material-ui/core/StepLabel'
import StepIconDefault from './step-icon-default'
import useStyles from './styles'
import { QontoConnectorDefault } from './connectors'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import Tooltip from '@material-ui/core/Tooltip'
import { Link } from 'react-router-dom'

const DEFAULT_INFO_TOOLTIP = 'OS Reprovada'
const DEFAULT_TOOLTIP_PLACEMENT = 'right'

export default function CustomStepper({ steps, stepStatus, activeStep, proposalResponse, leadTimes, dataOS, id, description, onClickDetails }) {
  const classes = useStyles()

  const getStepClass = (key, stepStatus) => {
    if (key !== 1) {
      return getStepLineClass(key, stepStatus)
    }
    return classes.hiddenConnector
  }

  const getStepLineClass = (key, stepStatus) => {
    if (activeStep !== key) {
      return ''
    }
    switch (stepStatus) {
      case 'waiting':
        return classes.waitingToSuccess
      case 'error':
        return classes.errorToSuccess
      default:
        return ''
    }
  }

  const getStepStatusObject = (step) => {
    return {
      proposalResponse: step === 4 ? proposalResponse : undefined,
      activeStepStatus: stepStatus,
      leadTimes,
    }
  }

  const getStepLabel = (label) => {
    if (!label) {
      return undefined
    }
    return (
      <>
        {label.map((labelChild, key) => {
          if (typeof labelChild !== 'string') {
            return (
              <div key={key} className={classes.labelContainer}>
                {labelChild.map((child, k) => {
                  return (
                    <div
                      key={k}
                      className={classes.labelContainerChildren}>
                      {child.map(i => getDefaultLabel(i))}</div>
                  )
                })}
              </div>
            )
          }
          return <div className={classes.serviceTitle} key={key}>{labelChild}</div>
        })}
        {getTooltip()}
      </>
    )
  }

  const getTooltip = () => {
    if (typeof proposalResponse !== 'undefined' && proposalResponse === 'return') {
      return (
        <Tooltip title={DEFAULT_INFO_TOOLTIP} placement={DEFAULT_TOOLTIP_PLACEMENT}>
          <ErrorOutline className={classes.alert} />
        </Tooltip>
      )
    }
  }

  const getDefaultLabel = (label) => {
    if (typeof label === 'undefined') {
      return undefined
    }
    if (typeof label !== 'string') {
      return label.map((item, i) => {
        if (typeof item !== 'string') {
          return <div key={i} className={classes.specialLabelItem}>{item}</div>
        }
        return renderLabelItem(item)
      })
    }
    return renderLabelItem(label)
  }

  const renderLabelItem = (item) => {
    if (!item) {
      return undefined
    }
    return (
      <div key={item} className={classes.labelItem}>{item}</div>
    )
  }

  const renderLabel = (label, specialClass) => {
    if (typeof label === 'undefined') {
      return undefined
    }
    if (typeof label !== 'string') {
      return <div className={[specialClass, classes.labelContainer].filter(x => x).join(' ')}>{
        label.map((item, k) => {
          if (item) {
            return (
              <div key={k} className={classes.labelContainerChildren}>{getDefaultLabel(item)}</div>
            )
          }
          return null
        })
      }
      </div>
    }
    return label
  }

  const getSteps = (steps) => {
    if (!steps) {
      return
    }
    return steps.map((label, i) => {
      if (typeof label === 'undefined') {
        return undefined
      }
      if (i <= 0) {
        return <Step key={i} className={classes.stepTextContainer}>
          <StepLabel classes={{
            label: classes.labelItem
          }}>
            {getStepLabel(label)}
          </StepLabel>
        </Step>
      }
      return (
        <Step key={i} className={getStepClass(i, stepStatus)}>
          <StepLabel
            classes={{
              label: classes.stepLabel
            }} StepIconProps={getStepStatusObject(i)} StepIconComponent={StepIconDefault}
          >
            {renderLabel(label)}
          </StepLabel>
        </Step>
      )
    })
  }

  return (
    <div>
      <Stepper className={classes.root} alternativeLabel activeStep={activeStep} connector={<QontoConnectorDefault />}>
        {getSteps(steps)}
      </Stepper>
      <div onClick={onClickDetails} className={classes.buttonContainer}>
        <Link
          to={{
            pathname: `/detalhes/${id}/${description}`,
            state: dataOS
          }}>
          {'Ver mais detalhes'}
        </Link>
      </div>
    </div>
  )
}
