
import React from 'react'
import useStyles from './styles'
import Check from '@material-ui/icons/CheckCircleRounded'
import WatchLater from '@material-ui/icons/WatchLater'
import Error from '@material-ui/icons/Error'
import Tooltip from '@material-ui/core/Tooltip'

import clsx from 'clsx'
import { steps } from '@pages/servicos/status'

export default function QontoStepIcon(props) {
  const { active, completed, activeStepStatus, icon, leadTimes } = props

  const classes = useStyles()

  const getIcon = (status) => {
    switch (status) {
      default:
        return <WatchLater className={classes.waiting} />
      case 'error':
        return <Error className={classes.error} />
      case 'completed':
        return <Check className={classes.completed} />
    }
  }

  const getLeadTimeStep = () => {
    const currentStep = icon - 1
    if (steps[currentStep]) {
      const description = steps[currentStep] == "coleta" ? "processo" : steps[currentStep]

      return (
        <div className={classes.leadTimeContainer}>
          <span>{`Lead Time - ${description}`}</span>
          <span>{getLeadTimeDiffText(leadTimes[currentStep])}</span>
        </div>
      )
    }
  }

  const getLeadTimeDiffText = (quantity) => {
    if (quantity > 1) {
      return `${quantity} dias.`
    }
    return `${quantity} dia.`
  }

  const getTooltipOnStep = (childNode) => {
    const currentStep = icon - 1

    if (leadTimes[currentStep] && leadTimes[currentStep] !== null) {
      return (
        <Tooltip
          classes={{
            popper: classes.tooltip
          }}
          placement={'top'}
          title={getLeadTimeStep()}>
          {childNode}
        </Tooltip>
      )
    }
    return childNode
  }

  const getCompletedOrInactiveIcon = () => {
    if (completed) {
      return getTooltipOnStep(getIcon('completed'))
    }
    return (
      <div className={classes.circle} />
    )
  }

  if (active && !completed) {
    const childNode = <div className={classes.root}>
      {getIcon(activeStepStatus)}
    </div>
    return getTooltipOnStep(childNode)
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {getCompletedOrInactiveIcon()}
    </div>
  )
}
