import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  loginFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto 0',
    paddingBottom: '112.5px',
    '& input': {
      fontFamily: '\'Montserrat\', sans-serif'
    },
    '& button': {
      margin: '20px auto'
    }
  },
  loginInputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px'
  },
  textField: {
    minWidth: 'calc(25vw - 30px)',
    height: '32px',
    padding: '0 15px',
    border: '1px solid #CCCCCC',
    borderRadius: '2px'
  },
  title: {
    marginBottom: '35px',
    color: theme.primaryText,
    fontWeight: 'normal',
    fontSize: '2vw'
  },
  label: {
    fontWeight: '600',
    color: theme.primaryText,
    fontSize: '1vw',
    marginBottom: '15px'
  },
  loginButton: {
    width: '15vw',
    height: '35px',
    '& span': {
      textTransform: 'none',
      fontSize: '1vw',
      fontWeight: '600',
      ['@media (min-width: 1600px)']: {
        fontSize: 'initial'
      }
    },
    ['@media (min-width: 1600px)']: {
      height: '42px',
    }
  },
  circularProgress: {
    '& svg': {
      color: '#fff'
    }
  },
  loginMessage: {
    color: theme.rebuild,
    fontSize: '12px',
    lineHeight: '12px',
    textAlign: 'center',
    minHeight: '12px'
  }
}))

export default styles
