import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import Sidebar from '@layouts/sidebar'
import { CSSTransition } from 'react-transition-group'

export default function BasePage({ activeRoute, children }) {
  const classes = useStyles()
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [isLoaded])
  return (
    <div className={`${classes.root}`}>
      <div className={classes.fullfiledMainContainer}></div>
      <div className={classes.fullfiledOtherContainer}></div>
      <div className={`${classes.container} root`}>
        <Sidebar />
        <CSSTransition in={isLoaded} timeout={1000} classNames='root'>
          {children}
        </CSSTransition>
      </div>
    </div>
  )
}
