import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '30px 0',
        width: '450px',
        '&:hover': {
            cursor: 'pointer'
        },
        '&:focus': {
            outline: 'none'
        }
    }
}))

export default styles
