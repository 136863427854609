import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    '& h2': {
      color: theme.white,
      fontWeight: 'normal',
      fontSize: '32px',
      margin: '0 0 20px 0',
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 0px',
    position: 'fixed',
    top: 160,
    width: 'calc(100% - 215px)',
    height: '100%',
  },
  headingItem: {
    flex: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 'bold',
    color: theme.primaryText
  },
  rowPaper: {
    margin: '10px 15px',
    padding: '15px',
    boxShadow: '0px 1px 8px 0px rgba(0,0,0,0.1) !important',
    "& > *": {
      borderRadius: "8px !important",
    },
  },
  heading: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: 37,
    position: 'fixed',
    top: 160,
    background: theme.white,
    zIndex: 1200,
    borderBottom: `1px solid ${theme.primaryColor}`,
    width: 'calc(100% - 215px) !important',
  },
  emptyResult: {
    margin: theme.spacing(4),
    color: theme.primaryText,
    height: '40px',
    alignSelf: 'center'
  },
  staticMessage: {
    background: theme.rebuild,    
    height: '40px',
    alignSelf: 'center',
    margin: '15px',
    
    textAlign: 'center',
    lineHeight: '40px',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: theme.white,
    fontSize: '12px'
  },
  linkElement: {
    textDecoration: 'none'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'fixed',
    padding: '35px 0 0 0',
    zIndex: 1200,
    top: 0,
    background: theme.primaryColor,
    width: 'calc(100% - 215px)',
    ['@media (min-width:1600px)']: {
      width: 'calc(100% - 250px)',
    }
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    background: theme.primaryColor,
  },
  scrollable: {
    overflowY: 'scroll',
    marginBottom: '150px',
    marginTop: '60px',
    paddingTop: '30px'
  },
  paginationContainer: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    color: theme.primaryText,
    fontSize: '12px',
    padding: '15px'
  }
}))

export default styles
