import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  root: {
    padding: '10px 0'
  },
  stepTextContainer: {
    '& svg': {
      display: 'none'
    },
  },
  serviceTitle: {
    height: '36px',
    marginTop: '-10px',
  },
  stepLabel: {
    fontSize: '12px',
    marginTop: '10px !important',
    padding: '10px 0',
    width: '100%'
  },
  stepAsText: {
    color: 'red',
    fontSize: '8px',
  },
  alert: {
    fontSize: 18,
    color: theme.fastService,
    marginTop: '5px',
    display: 'initial !important'
  },
  hiddenConnector: {
    '& div': {
      '& span': {
        visibility: 'hidden'
      }
    }
  },
  waitingToError: {
    '& div': {
      '& span': {
        border: 'none !important',
        height: '3px',
        backgroundImage: `linear-gradient(to right, ${theme.repair}, ${theme.fastService}) !important`
      }
    }
  },
  waitingToSuccess: {
    '& div': {
      '& span': {
        border: 'none !important',
        height: '3px',
        backgroundImage: `linear-gradient(to right, ${theme.healthy}, ${theme.fastService}) !important`
      }
    }
  },
  errorToSuccess: {
    '& div': {
      '& span': {
        border: 'none !important',
        height: '3px',
        backgroundImage: `linear-gradient(to right, ${theme.healthy}, ${theme.rebuild}) !important`
      }
    }
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& a': {
      textDecoration: 'none',
      color: theme.primaryText,
      fontSize: '14px',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  labelContainer: {
    display: 'flex',
    border: `1px solid rgba(0, 0, 0, 0.1)`,
    borderRadius: '5px',
    padding: '10px 5px',
    justifyContent: 'space-around',
    alignItems: 'baseline',
    height: '60px',
    fontSize: '9px',
    '@media (max-width:1366px)': {
      padding: '10px 2px'
    }
  },
  labelContainerChildren: {
    flex: 1,
    alignSelf: 'center',
    margin: '0 2px'
  },
  labelItem: {
    '&:first-child': {
      color: theme.primaryText,
      marginBottom: '10px',
      '& svg': {
        margin: '-2px 0',
      },
    },
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: '8px',
  },
  specialLabelItem: {
    '&:first-child': {
      color: theme.primaryText,
      margin: '-2px 0'
    },
    color: 'rgba(0, 0, 0, 0.5)'
  }
}))

export default styles
