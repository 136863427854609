import React from 'react'
import ReactDOM from 'react-dom'
import './custom.scss'
import './index.css'
import App from './app'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { StateProvider } from './state'
require('dotenv').config()

export const defaultTheme = {
  primaryColor: '#0E1271',
  primaryText: '#43425d',
  tableDefaultColor: '#a3a6b4',
  buttonBorderRadius: '4px',
  healthy: '#6db52c',
  repair: '#e1570e',
  rebuild: '#da291c',
  fastService: '#1769b8',
  white: '#ffffff',
  offWhite: '#f1f1f7',
  blue: '#0e1271',
  secondaryTextColor: '#2b2698',
  tertiaryTextColor: '#707070',
  disabledTextColor: '#C0C0C0',
}

const theme = createMuiTheme({
  ...defaultTheme
})

const INITIAL_STATE = () => {
  const stored_value = JSON.parse(localStorage.getItem("userData") || "{}")
  return { userData: stored_value }
}

export function mainReducer(state, action) {
  switch (action.type) {
    case 'login':
      const newUserData = { ...action.payload }
      localStorage.setItem("userData", JSON.stringify(newUserData));

      return {
        ...state,
        userData: newUserData
      }
    case 'logoff':
      localStorage.clear();
      return {}
    case 'update_profile':
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload
        }
      }
    default:
      return state
  }
}

const render = Component => {
  return ReactDOM.render(
    <StateProvider initialState={INITIAL_STATE()} reducer={mainReducer}>
      <ThemeProvider theme={theme}>
        <Component />
      </ThemeProvider>
    </StateProvider>,
    document.getElementById('root')
  );
};

render(App)

if (module.hot) {
  module.hot.accept('./app', () => {
    const NextApp = require('./app').default
    render(NextApp)
  })
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
