import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useStateValue } from '@state/'
import useStyles from './styles'
import BasePage from '@pages/basePage'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import BarChart from './bar-chart'
import Data from '../servicos/data.json' // FAKEDATA
import LoadingLabel from '@layouts/loader'

export default function Dashboard() {
  const classes = useStyles()
  const [{ userData }, dispatch] = useStateValue()
  const [dataServiceOrders, updateServiceOrders] = useState(undefined)
  const [isResultsLoading, handleResultsLoading] = useState(true)

  useEffect(() => {
    if (!userData || !userData.headers) {
      return dispatch({ type: 'logoff' })
    }
    fetchDataServiceOrders()

  }, [userData, dispatch])

  const fetchDataServiceOrders = () => {
    const headers = userData && userData.headers ? userData.headers : undefined
    axios
      .get(`${process.env.REACT_APP_DEFAULT_API}/service_orders/`, {
        headers: { ...headers }
      })
      .then(response => {
        updateServiceOrders(Data.data || response.data)
        handleResultsLoading(false)
      })
      .catch(_error => {
        handleResultsLoading(false)
      })
  }

  const groupDataByStatus = () => {
    if (!dataServiceOrders) {
      return []
    }
    let data = {}
    dataServiceOrders.forEach(item => {
      if (data[item.last_status]) {
        data[item.last_status].push(item)
      } else {
        data[item.last_status] = [item]
      }
    })
    return data
  }

  const transformDataStatusToArray = () => {
    const data = groupDataByStatus()
    let chartArray = []
    const keys = Object.keys(data)
    keys.forEach(key => {
      chartArray.push({
        [key]: data[key]
      })
    })
    return chartArray
  }
  return (
    <BasePage>
      <div className={classes.content}>
        <Grid className={classes.titleContainer}>
          <h2>Dashboard - Quantidade de OS por Status</h2>
        </Grid>
        <Paper className={classes.container}>
          {
            isResultsLoading && <LoadingLabel />
          }
          {
            !isResultsLoading && <BarChart data={transformDataStatusToArray()} />
          }
        </Paper>
      </div>
    </BasePage>
  )
}