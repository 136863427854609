import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    '& .root-enter': {
      opacity: 0
    },
    '& .root-enter-active': {
      opacity: 1,
      transition: 'opacity 1000ms'
    }
  },
  container: {
    padding: '40px 0',
    width: '100%',
    height: '100vh',
    overflowY: 'auto',
    display: 'flex'
  },
  fullfiledMainContainer: {
    position: 'fixed',
    zIndex: '0',
    width: '100%',
    height: '270px',
    background: theme.blue,
  },
  fullfiledOtherContainer: {
    position: 'fixed',
    zIndex: '0',
    width: '100%',
    height: 'calc(100% - 270px)',
    top: '270px',
    background: theme.offWhite,
  }
}))

export default styles
