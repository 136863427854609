import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  loginContainer: {
    height: '100vh',
    display: 'flex'
  },
  loginLeftSide: {
    backgroundImage: "url('./background_blue.png')",
    backgroundPosition: 'center',
    flex: 0.35
  },
  loginRightSide: {
    flex: 0.65,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    background: theme.white,
    '& img': {
      marginLeft: 'auto',
      padding: '50px'
    }
  }
}))

export default styles
