import React, { memo, useState } from 'react'
import useStyles from './styles'
import Paper from '@material-ui/core/Paper'
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/ClearOutlined'


function SearchByText({ onClick, onClearSearch }) {
  const classes = useStyles()
  const [inputValue, updateInputValue] = useState("")
  const [activeSearchByOS, handleSearchByOS] = useState(false)

  const onChangeInput = (e) => {

    e.preventDefault()

    updateInputValue(e.target.value)

    handleSearchByOS(false)

  }

  const onClickButton = (e) => {
    e.preventDefault()
    if (activeSearchByOS) {
      handleSearchByOS(false)
      updateInputValue("")
      onClearSearch && onClearSearch()
      return
    }
    if (onClick) {
      onClick(inputValue)
    }
    handleSearchByOS(true)
  }

  return (
    <Paper className={classes.root}>
      <form
        className={classes.form}
        onSubmit={onClickButton}>
        <InputBase
          className={classes.inputContainer}
          value={inputValue}
          placeholder="OS, NF ou descrição"
          onChange={onChangeInput}
        />
        <IconButton
          onClick={onClickButton}
          className={classes.iconButton}
          aria-label="search">
          {activeSearchByOS ? <ClearIcon /> : <SearchIcon />}
        </IconButton>
      </form>
    </Paper>
  )
}

export default memo(SearchByText)