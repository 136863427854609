import React, { useState } from 'react'
import AlertDialog from '@layouts/dialog'
import useStyles from './styles'
import Logo from '@imgs/logo.png'
import { useStateValue } from '../../state'

import Configuracoes from '@material-ui/icons/Face'
import Home from '@material-ui/icons/ListAlt'
import Relatorios from '@material-ui/icons/SaveAlt'
import Sair from '@material-ui/icons/PowerSettingsNew'
import DashboardDialog from './dashboard-dialog'
import SetupDialog from './setup-dialog'

import { Link } from 'react-router-dom'

const DEFAULT_AVATAR = 'https://image.flaticon.com/icons/svg/145/145843.svg'

export default function Sidebar() {
  const classes = useStyles()
  const [{ userData }, dispatch] = useStateValue()
  const [isLogoutDialogOpen, handleLogoutDialog] = useState(false)
  const [isDashboardDialogOpen, handleDashboardDialog] = useState(false)
  const [isSetupDialogOpen, handleSetupDialog] = useState(false)

  const getUserAvatar = () => {
    if (userData && userData.avatar_url) {
      return userData.avatar_url
    }
    return DEFAULT_AVATAR
  }

  return (
    <div className={classes.drawer}>
      <div className={classes.listMenus}>
        <Link to={'/servicos'}>
          <img className={classes.logoImg} src={Logo} alt='Logo' />
        </Link>
        <div className={classes.profileContainer}>
          <img className={classes.imageProfile} src={getUserAvatar()} alt="User Profile" />

          <div className={classes.userData}>
            {userData && (userData.name || 'John Doe')}
          </div>
          <div className={classes.userEmail}>
            {userData && (userData.role_name || 'Usuário')}
          </div>
        </div>
        <div className={classes.menuContainer}>
          <Link className={classes.defaultIconContainer} to={'/servicos'}>
            <Home className={classes.defaultIcon} />
            {'Home'}
          </Link>
          {/*<div className={classes.disabledIconContainer}>
            <Notificacoes className={classes.disabledIcon} />
            {'Notificações'}
          </div>*/}
          <div className={classes.defaultIconContainer}
            onClick={() => handleSetupDialog(true)}>
            <Configuracoes className={classes.defaultIcon} />
            {'Configurações'}
          </div>
          {/*
          <div className={classes.defaultIconContainer}>
            <Link className={classes.defaultIconContainer} to={'/dashboard'}>
              <Dashboard className={classes.defaultIcon} />
              {'Dashboard'}
            </Link>
          </div>
          */}
          {/*<div className={classes.disabledIconContainer}>
            <Documentos className={classes.disabledIcon} />
            {'Documentos'}
          </div> */}
          <div
            className={classes.defaultIconContainer}
            onClick={() => handleDashboardDialog(true)}>
            <Relatorios className={classes.defaultIcon} />
            {'Relatórios'}
          </div>
          {/*<div className={classes.defaultIconContainer}>
            <HighLightOff className={classes.defaultIcon} />
            {'Sair'}
          </div> */}
          <div
            className={classes.defaultIconContainer}
            onClick={() => handleLogoutDialog(true)}>
            <Sair className={classes.defaultIcon} />
            {'Sair'}
          </div>
        </div>
      </div>
      <AlertDialog
        open={isLogoutDialogOpen}
        handleDisagree={() => handleLogoutDialog(false)}
        handleAgree={() => dispatch({ type: 'logoff' })}
        title={'Deseja realmente sair do sistema?'}
        agreeText={'Sair'}
        disagreeText={'Não'}>
        {'Ao clicar em Sair você será redirecionado para tela de login.'}
      </AlertDialog>
      <DashboardDialog
        isDashboardDialogOpen={isDashboardDialogOpen}
        handleDashboardDialog={handleDashboardDialog}
      />
      <SetupDialog
        isSetupDialogOpen={isSetupDialogOpen}
        handleSetupDialog={handleSetupDialog} />
    </div>
  )
}
