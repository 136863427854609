import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '500px',
        '& span': {
            marginBottom: '10px'
        }
    },
}))

export default styles
