import React from 'react'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'

import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'

import useStyles from './styles'


export default function FilterByMultiple({ title, title_singular, optionsList,/* handleStateChange, value = [], */ notifyValueChanged }) {

  const classes = useStyles()

  const [currentState, handleStateChange] = React.useState([]);

  const handleChange = (event) => handleStateChange(event.target.value)

  const onClose = () => {
    setTimeout(() => {
      notifyValueChanged(currentState)
    }, 50);
  }

  const getLabel = (selected) => {
    if (selected.length === 0)
      return <em>Todos os {title}</em>

    if (selected.length === 1)
      return <em>{selected.length} {title_singular || title} selecionado</em>

    return <em>{selected.length} {title} selecionados</em>
  }

  return (
    <Paper className={classes.root}>
      <Select
        multiple
        displayEmpty
        className={classes.textContainer}
        onChange={handleChange}
        onClose={onClose}
        input={<Input disableUnderline={true} />}
        value={currentState}
        renderValue={getLabel}
      >
        {optionsList.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            <Checkbox checked={currentState.indexOf(option.key) > -1} />
            <ListItemText disableTypography={true} className={classes.listItemText} primary={option.text} />
          </MenuItem>
        ))}
      </Select>
    </Paper>
  )
}