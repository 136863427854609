import React, { useState } from 'react'
import Dropzone from '@layouts/dropzone'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import useStyles from './styles'
import { useStateValue } from '@state/'
import AlertDialog from '@layouts/dialog'
import TextMaskCustom from '@layouts/text-mask-custom'
import axios from 'axios'


const DEFAULT_AVATAR = 'https://image.flaticon.com/icons/svg/145/145843.svg'
const DEFAULT_INITIAL_TEXT = 'Clique aqui ou arraste o arquivo para carregar nova foto.'
const DEFAULT_FILE_ACTIVE_TEXT = ` carregado com sucesso.`
const DEFAULT_ACCEPT_TYPES = 'image/jpeg, image/png'

export default function SetupDialog({ isSetupDialogOpen, handleSetupDialog }) {
  const classes = useStyles()
  const [{ userData }, dispatch] = useStateValue()
  const [fileUploaded, handleFileUpload] = useState(undefined)
  const [imageSrc, handleImageSrc] = useState(userData && (userData.avatar_url || DEFAULT_AVATAR))
  const [inputValue, onChangeInputValue] = useState(userData && (userData.phone_number || ""))

  const [password, setPassword] = React.useState('')
  const handlePasswordChange = (event) => { setPassword(event.target.value) }

  const [passwordConfirmation, setPasswordConfirmation] = React.useState('')
  const handlePasswordConfirmationChange = (event) => { setPasswordConfirmation(event.target.value) }

  const [errorProps, setErrorProps] = React.useState({ open: false, message: '' })
  const handleErrorOpen = (message) => { setErrorProps({ open: true, message }) }
  const handleErrorClose = (event, reason) => { setErrorProps({ open: false, message: '' }) }

  const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />

  const checkValidationErrors = async () => {
    const isPasswordEntered = password && password.length > 0
    const isPasswordConfirmationEntered = passwordConfirmation && passwordConfirmation.length > 0
    const isPasswordChanged = isPasswordEntered || isPasswordConfirmationEntered

    if (isPasswordChanged && !isPasswordEntered) {
      handleErrorOpen('É necessário informar a nova senha.')
      return true
    }

    if (isPasswordChanged && !isPasswordConfirmationEntered) {
      handleErrorOpen('É necessário informar a confirmação da nova senha.')
      return true
    }

    if (isPasswordChanged && password.length < 6) {
      handleErrorOpen('A nova senha precisa ter ao menos 6 caracteres.')
      return true
    }

    if (isPasswordChanged && password !== passwordConfirmation) {
      handleErrorOpen('A nova senha e a confirmação não podem ser diferentes.')
      return true
    }

    return false
  }

  const onUpdateProfile = async () => {

    const hasErrors = await checkValidationErrors()
    if (hasErrors) return


    const updated_profile = await updateProfile()

    if (updated_profile) {
      dispatch({
        type: 'update_profile',
        payload: {
          ...updated_profile
        }
      })
    }
    handleSetupDialog(false)
    handleFileUpload(undefined)
  }

  const updateProfile = async () => {
    const formData = new FormData()

    formData.append('name', userData.name)
    formData.append('phone_number', inputValue)

    const uploaded_file = getAttachmentFile()
    if (uploaded_file)
      formData.append('avatar', getAttachmentFile())

    const isPasswordChanged = password.length > 0 && passwordConfirmation.length > 0
    if (isPasswordChanged) {
      formData.append('password', password)
      formData.append('password_confirmation', passwordConfirmation)
    }

    let result = {}
    try {

      const controller_name = userData.type === "Client" ? 'clients' : 'representatives'

      result = await axios.patch(`${process.env.REACT_APP_DEFAULT_API}/${controller_name}/${getUserID()}`, formData, {
        headers: {
          ...userData.headers,
          'content-type': 'multipart/form-data'
        }
      })
    } catch (error) {
      console.log(error)
    }

    return result.data
  }

  const getAttachmentFile = () => {
    if (fileUploaded && fileUploaded[0]) {
      return fileUploaded[0]
    }

    return null
  }

  const getUserID = () => {
    if (userData && userData.id) {
      return userData.id
    }
  }

  const getUserName = () => userData && userData.name

  const onDropFiles = (acceptedFiles) => {

    const reader = new FileReader()
    reader.onload = () => {
      handleImageSrc(reader.result)
    }
    if (acceptedFiles) {
      acceptedFiles.map(file => reader.readAsDataURL(file))
      handleFileUpload(acceptedFiles)
    }
  }

  const handleDisagreeUpload = () => {
    handleFileUpload(undefined)

    handleSetupDialog(false)
  }

  const getFileName = () => {
    let fileName
    if (fileUploaded) {
      fileUploaded.map(file => fileName = file.name || undefined)
    }
    return fileName
  }

  const getFileNameShort = () => {
    const fileName = getFileName()
    if (!fileName) {
      return undefined
    }
    if (fileName.length > 30) {
      return `${fileName.slice(0, 22)}...${fileName.slice(fileName.length - 5, fileName.length)}`
    }
    return fileName
  }

  return (
    <AlertDialog
      open={isSetupDialogOpen}
      handleDisagree={() => handleDisagreeUpload(false)}
      handleAgree={() => onUpdateProfile()}
      title={`Editar informações de ${getUserName()}`}
      agreeText={'Atualizar Perfil'}
      disagreeText={'Cancelar'}
      isAgreeDisabled={false}>

      <div>
        <div className={classes.infosContainer}>
          <TextField
            id="standard-name"
            label="Telefone"
            value={inputValue}
            onChange={(e) => onChangeInputValue(e.target.value)}
            margin="normal"
            InputProps={{
              inputComponent: TextMaskCustom
            }}
            helperText="Clique para editar"
          />
          <img className={classes.imageProfile} src={imageSrc} alt="User Profile" />
        </div>
        <Dropzone
          fileName={getFileNameShort()}
          acceptType={DEFAULT_ACCEPT_TYPES}
          onDropFiles={(acceptedFiles) => onDropFiles(acceptedFiles)}
          defaultInitialText={DEFAULT_INITIAL_TEXT}
          defaultFileActiveText={DEFAULT_FILE_ACTIVE_TEXT} />
        <div className={classes.passwordContainer}>

          <div className={classes.passwordInputGroup}>
            <label className={classes.label} htmlFor='input-password'>
              {'Nova Senha'}
            </label>
            <input
              autoComplete='off'
              className={classes.textField}
              type='password'
              placeholder='Digite sua nova senha'
              value={password}
              onChange={handlePasswordChange}
            />
          </div>

          <div className={classes.passwordInputGroup}>
            <label className={classes.label} htmlFor='input-password'>
              {'Confirme a Nova Senha'}
            </label>
            <input
              autoComplete='off'
              className={classes.textField}
              type='password'
              placeholder='Confirme sua nova senha'
              value={passwordConfirmation}
              onChange={handlePasswordConfirmationChange}
            />
          </div>
        </div>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={errorProps.open}
          autoHideDuration={5000}
          onClose={handleErrorClose}>
          <Alert onClose={handleErrorClose} severity="warning">
            {errorProps.message}
          </Alert>
        </Snackbar>
      </div>


    </AlertDialog>
  )
}