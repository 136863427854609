import React, { useState } from 'react'
import useStyles from './styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

function LoginForm ({ onSubmitForm, isLoading, loginMessage }) {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const onChangeEmail = e => {
    e.preventDefault()
    setEmail(e.target.value)
  }
  const onChangePassword = e => {
    e.preventDefault()
    setPassword(e.target.value)
  }
  const handleOnSubmitForm = e => {
    e.preventDefault()
    if (!email || !password || !onSubmitForm) {
      return
    }
    onSubmitForm(email, password)
  }
  return (
    <form className={classes.loginFormContainer} onSubmit={handleOnSubmitForm}>
      <h2 className={classes.title}>Login</h2>
      <div className={classes.loginInputGroup}>
        <label className={classes.label} htmlFor='input-email'>
          {'E-mail'}
        </label>
        <input
          autoComplete='username'
          className={classes.textField}
          placeholder='Digite seu e-mail'
          value={email}
          onChange={onChangeEmail}
        />
      </div>
      <div className={classes.loginInputGroup}>
        <label className={classes.label} htmlFor='input-password'>
          {'Senha'}
        </label>
        <input
          autoComplete='current-password'
          className={classes.textField}
          type='password'
          placeholder='Digite sua senha'
          value={password}
          onChange={onChangePassword}
        />
      </div>
      <div className={classes.loginMessage}>{loginMessage}</div>
      <Button
        onClick={handleOnSubmitForm}
        onSubmit={handleOnSubmitForm}
        type='submit'
        disabled={!(password && email)}
        className={classes.loginButton}
        variant='contained'
        color='primary'
      >
        {isLoading ? <CircularProgress size={18} className={classes.circularProgress} /> : 'Entrar'}
      </Button>
    </form>
  )
}

export default LoginForm
