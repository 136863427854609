import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import useStyles from './styles'


import DialogContentText from '@material-ui/core/DialogContentText';

const DEFAULT_REJECT_TEXT = () => <b>{"Erro ao anexar o arquivo, por favor tente novamente."}</b>;
const DEFAULT_DRAG_ACTIVE_TEXT = () => 'Solte o arquivo aqui...'
const DEFAULT_FILE_ACTIVE_TEXT = (file, text) => <><b>{file}</b>{text || ` anexado, clique em Continuar.`}</>
const DEFAULT_INITIAL_TEXT = (text) => text || 'Arraste e solte os arquivos aqui ou clique para selecionar.'


export default function Dropzone({onDropFiles, fileName, defaultInitialText, defaultFileActiveText, acceptType}) {
  const classes = useStyles()
  const [contentText, handleContentText] = useState(DEFAULT_INITIAL_TEXT(defaultInitialText))
  const multiple = false;
  const accept = acceptType
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    if(!acceptedFiles){
      handleContentText(DEFAULT_REJECT_TEXT())
    }
    if(acceptedFiles && onDropFiles){
      handleContentText(DEFAULT_INITIAL_TEXT(defaultInitialText))
      onDropFiles(acceptedFiles)
    }
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple, accept})

  const renderContent = () => {
    if(isDragActive){
      return(
        <DialogContentText>{DEFAULT_DRAG_ACTIVE_TEXT()}</DialogContentText>
      )
    }
    if(fileName){
      return(
        <DialogContentText>{DEFAULT_FILE_ACTIVE_TEXT(fileName, defaultFileActiveText)}</DialogContentText>
      )
    }
    return(
      <DialogContentText>{contentText}</DialogContentText>
    )
  }

  return (
    <div className={classes.root} {...getRootProps()}>
      <input {...getInputProps()} />
      {renderContent()}
    </div>
  )
}