import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useStateValue } from '../../state'
import useStyles from './styles'
import BasePage from '@pages/basePage'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import CustomStepper from '@layouts/stepper'
import { steps, statusStep, getStepLabels, statusFilterSteps, getLeadTime } from './status'
import LoadingLabel from '@layouts/loader'
import Fade from '@material-ui/core/Fade'
import FilterByText from './filter-by-text'
import FilterByMultiple from './filter-by-multiple'

export default function Servicos(props) {
  const classes = useStyles()
  const [{ userData }, dispatch] = useStateValue()
  const [shownServiceOrders, updateShownServiceOrders] = useState([])
  const [allServiceOrders, updateAllServiceOrders] = useState([])
  const [isResultsLoading, handleResultsLoading] = useState(true)

  const STATUS_FILTER = "STATUS_FILTER"
  const [statusFilter, updateStatusFilter] = useState([])

  const LOCATION_FILTER = "LOCATION_FILTER"
  const [locationList, updateLocationList] = useState([])
  const [locationFilter, updateLocationFilter] = useState([])

  const COMPONENT_GROUP_FILTER = "COMPONENT_GROUP_FILTER"
  const [componentGroupList, updateComponentGroupList] = useState([])
  const [componentGroupFilter, updateComponentGroupFilter] = useState([])

  // const ORDER_BUSINESS_FILTER = "ORDER_BUSINESS_FILTER"
  // const [orderBusinessList, updateOrderBusinessList] = useState([])
  // const [orderBusinessFilter, updateOrderBusinessFilter] = useState([])

  const SERVICE_CENTER_FILTER = "SERVICE_CENTER_FILTER"
  const [serviceCenterList, updateServiceCenterList] = useState([])
  const [serviceCenterFilter, updateServiceCenterFilter] = useState([])

  const TEXT_FILTER = "TEXT_FILTER"
  const [textFilter, updateTextFilter] = useState("")

  /*
    useEffect(() => {
      console.log('render')
    }, [shownServiceOrders])

    useEffect(() => {
      console.log('Servicos -> ComponentDidMount')
    }, [])
  */

  useEffect(() => {
    if (!userData || !userData.headers) {
      return dispatch({ type: 'logoff' })
    }

    fetchDataServiceOrders()
  }, [userData, dispatch])

  const getComponentGroupForItem = (item) => {

    if (`CLD`.includes(item.component_type_code))
      return { component_group_code: 'CLD', component_group_name: 'Cilindros' }

    if (`SPL`.includes(item.component_type_code))
      return { component_group_code: 'SPL', component_group_name: 'Cubo de Roda' }

    if (`ELE EDT`.includes(item.component_type_code))
      return { component_group_code: 'ELE', component_group_name: 'Elétrico' }

    if (`IGB`.includes(item.component_type_code))
      return { component_group_code: 'IGB', component_group_name: 'Painel IGBT' }

    if (`HID ROP PPM HID`.includes(item.component_type_code))
      return { component_group_code: 'HID', component_group_name: 'Hidráulico' }

    if (`IMP EQT`.includes(item.component_type_code))
      return { component_group_code: 'IMP', component_group_name: 'Implementos' }

    if (`BAS`.includes(item.component_type_code))
      return { component_group_code: 'BAS', component_group_name: 'Básculas' }

    if (`CGR CMD CPO`.includes(item.component_type_code))
      return { component_group_code: 'CGR', component_group_name: 'Caçambas' }

    if (`LAM`.includes(item.component_type_code))
      return { component_group_code: 'LAM', component_group_name: 'Lâminas' }

    if (`LIF`.includes(item.component_type_code))
      return { component_group_code: 'LIF', component_group_name: 'Lift Arm' }

    if (`MAD`.includes(item.component_type_code))
      return { component_group_code: 'MAD', component_group_name: 'Mandíbula' }

    if (`TAP`.includes(item.component_type_code))
      return { component_group_code: 'TAP', component_group_name: 'Tampa' }

    if (`MAQ EQP`.includes(item.component_type_code))
      return { component_group_code: 'MAQ', component_group_name: 'Máquinas' }

    if (`UCR`.includes(item.component_type_code))
      return { component_group_code: 'UCR', component_group_name: 'Material Rodante' }

    if (`MEC`.includes(item.component_type_code))
      return { component_group_code: 'MEC', component_group_name: 'Mecânico' }

    if (`MT`.includes(item.component_type_code))
      return { component_group_code: 'MT	', component_group_name: 'Motor Diesel' }

    if (`OTS`.includes(item.component_type_code))
      return { component_group_code: 'OTS', component_group_name: 'Outros' }

    if (`RM`.includes(item.component_type_code))
      return { component_group_code: 'RM	', component_group_name: 'Roda Motorizada' }

    if (`FAN`.includes(item.component_type_code))
      return { component_group_code: 'FAN', component_group_name: 'Sist.Arrefec.' }

    if (`SUB`.includes(item.component_type_code))
      return { component_group_code: 'SUB', component_group_name: 'Subcomponentes' }

    if (`SUS`.includes(item.component_type_code))
      return { component_group_code: 'SUS', component_group_name: 'Suspensão' }

    if (`DRV`.includes(item.component_type_code))
      return { component_group_code: 'DRV', component_group_name: 'Driver' }

    if (`PTO`.includes(item.component_type_code))
      return { component_group_code: 'TF', component_group_name: 'PTO' }

    if (`TF CT CF DIF BRK RG`.includes(item.component_type_code))
      return { component_group_code: 'TF', component_group_name: 'Trem de Força' }

    return { component_group_code: item.component_type_code, component_group_name: item.component_type_name }
  }

  // const getOrderBusinessFilterForItem = (item) => {
  //   return item.order_business == 'business_warranty' ? 'business_warranty' : 'other'
  // }

  const addFilterFieldsToData = (data) => {
    data.forEach((item) => {
      const { component_group_code, component_group_name } = getComponentGroupForItem(item)
      item["component_group_code"] = component_group_code
      item["component_group_name"] = component_group_name

      item["text_content"] = getTextSearchContent(item)
    })
  }


  const convertHashToList = (hash) => {
    let list = []
    for (var key in hash) {
      list.push({ key: key, text: hash[key] })
    }
    return list
  }

  const fillFilterLists = (data) => {
    let service_center_hash = {}
    let component_group_hash = {}
    let location_hash = {}
    // let order_business_hash = {
    //   'warranty': 'Garantia',
    //   'non_warranty': 'Não Garantia',
    // }

    data.forEach((item) => {
      service_center_hash[item.service_center_code] = item.service_center_name
      component_group_hash[item.component_group_code] = item.component_group_name
      location_hash[item.location_slug] = item.location_name
    })

    const service_center_list = convertHashToList(service_center_hash)
    updateServiceCenterList(service_center_list)

    const component_group_list = convertHashToList(component_group_hash)
    updateComponentGroupList(component_group_list)

    // const order_business_list = convertHashToList(order_business_hash)
    // updateOrderBusinessList(order_business_list)

    const location_list = convertHashToList(location_hash)
    updateLocationList(location_list)
  }

  const fetchDataServiceOrders = () => {
    const headers = userData && userData.headers ? userData.headers : undefined
    axios
      .get(`${process.env.REACT_APP_DEFAULT_API}/service_orders/`, {
        headers: { ...headers }
      })
      .then(response => {
        let data = response.data

        addFilterFieldsToData(data)
        fillFilterLists(data)

        updateShownServiceOrders(data)
        updateAllServiceOrders(data)

        handleResultsLoading(false)
      })
      .catch(_error => {
        console.log(_error)
        updateShownServiceOrders([])
        handleResultsLoading(false)
      })
  }

  const getTextSearchContent = (data) => {
    const { code, description, invoice_entrance_number } = data
    const textContent = `${code} ${description} ${invoice_entrance_number}`

    return textContent.toLocaleLowerCase()
  }

  const onFilterChange = (filterName, newValue) => {

    handleResultsLoading(true)

    let text = textFilter
    let status = statusFilter
    let location = locationFilter
    let component_group = componentGroupFilter
    // let order_business = orderBusinessFilter
    let service_center = serviceCenterFilter

    switch (filterName) {
      case TEXT_FILTER:
        updateTextFilter(newValue.toLocaleLowerCase())
        text = newValue.toLocaleLowerCase()
        break
      case STATUS_FILTER:
        updateStatusFilter(newValue)
        status = newValue
        break
      case LOCATION_FILTER:
        updateLocationFilter(newValue)
        location = newValue
        break
      case COMPONENT_GROUP_FILTER:
        updateComponentGroupFilter(newValue)
        component_group = newValue
        break
      // case ORDER_BUSINESS_FILTER:
      //   updateOrderBusinessFilter(newValue)
      //   order_business = newValue
      //   break
      case SERVICE_CENTER_FILTER:
        updateServiceCenterFilter(newValue)
        service_center = newValue
        break
      default:
        // do nothing
        break
    }


    const filteredServiceOrders = [...allServiceOrders].filter(data => {

      if (!data) return false

      if (text.length > 0 && !data.text_content.includes(text)) return false

      if (status.length > 0 && !status.includes(data.last_status)) return false

      if (location.length > 0 && !location.includes(data.location_slug)) return false

      if (component_group.length > 0 && !component_group.includes(data.component_group_code)) return false

      // if (order_business.length > 0 && !order_business.includes(data.order_business_filter)) return false

      if (service_center.length > 0 && !service_center.includes(data.service_center_code)) return false

      return true
    })

    updateShownServiceOrders(filteredServiceOrders)

    setTimeout(() => handleResultsLoading(false), 200)

  }

  const onClickDetails = () => {
    handleResultsLoading(true)
  }

  const renderPaperItem = (data) => {
    if (!data) {
      return undefined
    }

    return (
      <Paper
        key={data.id}
        className={classes.rowPaper}>
        <CustomStepper
          proposalResponse={data.proposal_response_status}
          stepStatus={getStepStatus(data.last_status)}
          steps={[...getStepLabels(data)]}
          activeStep={getStep(data.last_status)}
          dataOS={data}
          id={data.id}
          description={`${data.code} - ${data.description}`}
          leadTimes={getLeadTime(data)}
          onClickDetails={onClickDetails}
        />
      </Paper>
    )
  }

  const getStep = (status) => steps.indexOf(statusStep[status]) || ''

  const getStepStatus = (status) => {
    if (status === 'waiting_approval') {
      return 'error'
    }
    if (status.includes('completed')) {
      return 'completed'
    }
    return 'waiting'
  }

  const emptyResult = () => <Fade in={!isResultsLoading}>
    <div className={classes.emptyResult}>Nenhum resultado encontrado.</div>
  </Fade>

  /*
  const staticMessage = () => (
    <div className={classes.staticMessage}>Atualização de status de ordens de serviço temporariamente indisponível. Última atualização em 24/06/2021</div>
  )
  */

  return (
    <BasePage activeRoute={props.location.pathname}>
      <div className={classes.content}>
        <Grid className={classes.titleContainer}>
          <h2>Gestão de Componentes</h2>
          <div className={classes.filterContainer}>
            <FilterByText
              onClick={(value) => onFilterChange(TEXT_FILTER, value)}
              onClearSearch={() => onFilterChange(TEXT_FILTER, '')} />
            {/*<FilterByMultiple
               title="Tipos"
               title_singular="Tipo"
               optionsList={orderBusinessList}
               notifyValueChanged={(value) => onFilterChange(ORDER_BUSINESS_FILTER, value)}
             /> */}
            <FilterByMultiple
              title="Componentes"
              title_singular="Componente"
              optionsList={componentGroupList}
              notifyValueChanged={(value) => onFilterChange(COMPONENT_GROUP_FILTER, value)}
            />
            <FilterByMultiple
              title="Locais"
              title_singular="Local"
              optionsList={locationList}
              notifyValueChanged={(value) => onFilterChange(LOCATION_FILTER, value)}
            />
            <FilterByMultiple
              title="CRKs"
              title_singular="CRK"
              optionsList={serviceCenterList}
              notifyValueChanged={(value) => onFilterChange(SERVICE_CENTER_FILTER, value)}
            />
            <FilterByMultiple
              title="Status"
              optionsList={statusFilterSteps}
              notifyValueChanged={(value) => onFilterChange(STATUS_FILTER, value)}
            />
          </div>
        </Grid>
        <Grid
          container
          direction='row'
          justify='center'
          alignItems='center'
          className={classes.heading}
        >{
            steps && steps.map(item => <div key={item} className={classes.headingItem}>{item}</div>)
          }
        </Grid>
        <Paper className={classes.container}>
          {isResultsLoading && <LoadingLabel />}
          {!isResultsLoading && !shownServiceOrders && emptyResult()}
          <div className={classes.scrollable}>
            { /* !isResultsLoading && staticMessage() */}
            <div className={classes.paginationContainer}>
              {!isResultsLoading && `Foram encontradas ${shownServiceOrders && shownServiceOrders.length} Ordens de Serviço`}
            </div>
            {!isResultsLoading && shownServiceOrders && shownServiceOrders.map((data) => renderPaperItem(data))}
          </div>
        </Paper>
      </div>
    </BasePage>
  )
}
