import React from 'react'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import useStyles from './styles'


export default function AlertDialog({ open, title, children, agreeText, disagreeText, handleAgree, handleDisagree, isAgreeDisabled = false }) {

  const classes = useStyles()
  const renderDialogContent = (content) => {
    if (typeof content !== 'string') {
      return content
    }
    return (
      <DialogContentText id="alert-dialog-description">
        {content}
      </DialogContentText>
    )
  }
  return (
    <Dialog
      open={open}
      onClose={handleDisagree}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        root: classes.root
      }}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
          {renderDialogContent(children)}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDisagree} color="primary">
          {disagreeText}
        </Button>
        <Button onClick={handleAgree} color="primary" disabled={isAgreeDisabled} autoFocus>
          {agreeText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}