import React, { useRef, useEffect } from 'react'
import Chart from 'chart.js'
import { chartStatus } from '@pages/servicos/status'
import { defaultTheme } from '@app/index'

const defaultOptions = (min = 0, max = 100) => {
  return ({
    scales: {
      yAxes: [
        {
          ticks: {
            min,
            max,
          }
        }
      ]
    }
  })
}

export default function BarChart({ data, options }) {
  const canvasRef = useRef(null)

  const getLabels = () => {
    let labels = []
    data.forEach(item => {
      const status = Object.keys(item).toString()
      labels.push(chartStatus[status])
    })
    return labels
  }

  const getValues = () => {
    let values = []
    data.forEach(item => {
      if (typeof item === 'object') {
        const key = Object.keys(item).toString()
        values.push({
          value: item[key],
          color: getColor(item)
        })
      }
    })
    return values
  }

  const getDataOfDatasets = () => {
    const data = getValues()
    const dataOfDatasets = data.map((item, i) => data[i].value.length)
    return dataOfDatasets
  }

  const getColor = (context) => {
    if (typeof context === 'object') {
      const status = Object.keys(context).toString()
      if (status === 'waiting_approval') {
        return defaultTheme.rebuild
      }
      if (status.includes('completed')) {
        return defaultTheme.healthy
      }
      return defaultTheme.fastService
    }
  }

  const getArrayOfColors = () => {
    const data = getValues()
    const colors = data.map((item, i) => data[i].color)
    return colors
  }

  useEffect(() => {
    mountChart()
    getArrayOfColors()
  })
  const mountChart = () => (
    new Chart(canvasRef.current, {
      type: 'bar',
      options: options || defaultOptions(0, data.length),
      data: {
        labels: getLabels(),
        datasets: [{
          label: 'Quantidade de OS',
          data: getDataOfDatasets(),
          backgroundColor: getArrayOfColors()
        }]
      }
    })
  )
  return (
    <div style={{ padding: 20 }}>
      <canvas ref={canvasRef} />
    </div>
  )
}