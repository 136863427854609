import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useStateValue } from '@state'
import useStyles from './styles'
import BasePage from '@pages/basePage'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import LoadingLabel from '@layouts/loader'
import Fade from '@material-ui/core/Fade'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
// todo unmock this
import Check from '@material-ui/icons/CheckCircleRounded'
import WatchLater from '@material-ui/icons/WatchLater'
import Error from '@material-ui/icons/Error'
import File from '@material-ui/icons/DescriptionOutlined'
import Message from '@material-ui/icons/Message'
import SaveIcon from '@material-ui/icons/Save'
import AttachFile from '@material-ui/icons/AttachFile'
import Grid from '@material-ui/core/Grid'
import { formatDateWithHour, formatCurrency } from '@helpers/'
import AlertDialog from '@layouts/dialog'
import Dropzone from '@layouts/dropzone'
import ArrowBack from '@material-ui/icons/ArrowBackOutlined'
import { Link } from 'react-router-dom'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';

const DEFAULT_TIMELINE_CONTENT_ID = 'historyContent'
const DEFAULT_PAGINATION_TEXT_ID = 'paginationText'

export default function Historico(props) {
  const classes = useStyles()
  const [{ userData }, dispatch] = useStateValue()
  const [dataServiceOrderHistory, updateServiceOrderHistory] = useState(undefined)
  const [isResultsLoading, handleResultsLoading] = useState(true)
  const [newMessage, updateNewMessageText] = useState('')
  const [isSavingNewMessage, handleSavingNewMessage] = useState(false)
  const [isDropzoneDialogOpen, handleDropzoneDialog] = useState(false)
  const [fileUploaded, handleFileUpload] = useState(undefined)
  const [activeOS, handleActiveOS] = useState(undefined)


  const getUserAvatar = () => {
    if (userData && userData.avatar_url) {
      return userData.avatar_url
    }
    return ""
  }


  const getServiceOrderID = (matchProps) => {
    if (!matchProps || !matchProps.params) {
      return undefined
    }
    return matchProps.params.id
  }

  const getDescription = (matchProps) => {
    if (!matchProps || !matchProps.params) {
      return undefined
    }
    return matchProps.params.description
  }


  const getHistoryState = () => {
    if (props && props.history && props.history.location && props.history.location.state) {
      return props.history.location.state
    }
    return undefined
  }

  const scrollToBottom = () => {
    if (document) {
      const paginationText = document.getElementById(DEFAULT_PAGINATION_TEXT_ID)
      const timeline = document.getElementById(DEFAULT_TIMELINE_CONTENT_ID)
      timeline.scrollTop = paginationText.offsetTop
    }
  }


  useEffect(() => {
    if (!userData || !userData.headers) {
      return dispatch({ type: 'logoff' })
    }
    axios
      .get(`${process.env.REACT_APP_DEFAULT_API}/service_order_histories?service_order_id=${getServiceOrderID(props.match)}`, {
        headers: { ...userData.headers }
      })
      .then(response => {
        updateServiceOrderHistory(response.data)
        handleActiveOS(getHistoryState())
        handleResultsLoading(false)
      })
      .then(() => {
        scrollToBottom()
      })
      .catch(_error => {
        handleResultsLoading(false)
      })
  }, [userData, dispatch])

  const onSaveNewMessage = async () => {
    handleSavingNewMessage(true)

    const posted_message = await postNewMessage()
    if (posted_message && posted_message.id)
      updateServiceOrderHistory([...dataServiceOrderHistory, posted_message])

    updateNewMessageText('')
    handleFileUpload(undefined)
    handleSavingNewMessage(false)

    setTimeout(() => {
      scrollToBottom()
    }, 500)
  }

  const postNewMessage = async () => {

    const formData = new FormData()

    formData.append('service_order_id', getServiceOrderID(props.match))
    formData.append('description', newMessage)

    const uploaded_file = getAttachmentFile()
    if (uploaded_file)
      formData.append('attachment', getAttachmentFile())

    let result = {}
    try {
      result = await axios.post(`${process.env.REACT_APP_DEFAULT_API}/service_order_histories`, formData, {
        headers: {
          ...userData.headers,
          'content-type': 'multipart/form-data'
        }
      })
    } catch (error) {
      console.log(error)
    }

    return result.data
  }

  const getAttachmentFile = () => {
    if (fileUploaded && fileUploaded[0]) {
      return fileUploaded[0]
    }

    return null
  }

  const emptyResult = () => <Fade in={!isResultsLoading}>
    <div className={classes.emptyResult}>Nenhum resultado encontrado.</div>
  </Fade>

  const getFileDownload = (file, name) => {
    if (!file) {
      return
    }
    return (
      <div className={classes.downloadContainer}>
        <span>{name}</span>
        <div className={classes.downloadItem}>
          <File />
          <a href={file} target='_blank' rel="noopener noreferrer">{'Baixar'}</a>
        </div>
      </div>
    )
  }

  const getRowClass = (user_email) => {
    const current_item_user = user_email
    const logged_user = userData && userData.email

    if (current_item_user === logged_user) {
      return classes.rowItemRight
    }

    return classes.rowItemLeft
  }

  const getIcon = (status) => {
    switch (status) {
      case 'success':
        return <Check className={classes.successIcon} />
      case 'error':
        return <Error className={classes.errorIcon} />
      case 'waiting':
        return <WatchLater className={classes.waitingIcon} />
      case 'message':
        return <Message className={classes.successIcon} />
      default:
        return null
    }
  }

  const getLineClass = (user_email, key) => {
    const logged_user = userData && userData.email
    const previowsItem = dataServiceOrderHistory[key - 1]

    const previous_item_user = previowsItem && previowsItem.user_email
    const current_item_user = user_email

    const previous_item_left = previous_item_user !== logged_user
    const current_item_left = current_item_user !== logged_user

    if ((key <= 0) || (previous_item_left !== current_item_left)) {
      return
    }

    return classes.lineCard

  }

  const getCardTitleTooltip = (service) => {
    const name = service.title && service.title.replace("Criado por ", "")
    const company = service.company_name
    const user_email = service.user_email
    const user_phone_number = service.user_phone_number && service.user_phone_number.replace("(  )       -    ", "")

    return (
      <div>
        <span className={classes.cardTitleTooltip_Text}>{name}</span>
        {company && <br />}
        {company}
        {user_email && <br />}
        {user_email}
        {user_phone_number && <br />}
        {user_phone_number}
      </div>
    )
  }

  const renderCard = (service, i) => {
    if (!service) {
      return undefined
    }
    return (
      <Card className={getRowClass(service.user_email)} key={i}>
        <div className={[classes.defaultLineCard, getLineClass(service.user_email, i)].filter(x => x).join(' ')}></div>
        {getIcon(service.status)}
        {service.user_email && service.user_avatar_url && <img className={classes.userAvatar} src={service.user_avatar_url} alt="User Profile" />}
        <CardContent className={classes.cardContentContainer}>
          {renderCardMainContent(service)}
          {renderCardDescription(service)}
          {renderCardDownload(service)}
        </CardContent>
      </Card>
    )
  }

  const renderCardMainContent = (service) => {
    if (!service || !service.title) {
      return undefined
    }

    return (
      <CardContent className={classes.cardContentHeading}>
        <div className={classes.cardMainTitle}>
          <Tooltip title={getCardTitleTooltip(service)} arrow placement="top" classes={{ tooltip: classes.cardTitleTooltip_Tooltip, arrow: classes.cardTitleTooltip_Arrow }}>
            <span>{service.title}</span>
          </Tooltip>
          <span>{formatDateWithHour(service.created_at)}</span>
        </div>
      </CardContent>
    )
  }

  const renderCardDescription = (service) => {
    if (!service || !service.description) {
      return undefined
    }
    return (
      <CardContent className={classes.cardContentBody}>
        <div className={classes.cardMessageContainer}>
          <Typography className={classes.cardText}>
            {service.description}
          </Typography>
        </div>
      </CardContent>
    )
  }

  const renderCardDownload = (service) => {
    if (!service || !service.attachment_url) {
      return undefined
    }
    return (
      <CardContent className={classes.cardDownloadContainer}>
        <div className={classes.cardFileDownloadContainer}>
          {getFileDownload(service.attachment_url, service.attachment_name)}
        </div>
      </CardContent>
    )
  }

  const renderCardInputText = () => {
    return (
      <TextField
        id="outlined-textarea"
        multiline
        disabled={isSavingNewMessage}
        className={classes.textField}
        placeholder="Digite aqui a mensagem..."
        margin="normal"
        variant="outlined"
        value={newMessage}
        onChange={(e) => updateNewMessageText(e.target.value)}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          }
        }}
      />
    )
  }

  const cardNewMessage = () => {
    const DEFAULT_ICON_STATUS = 'message'
    return (
      <Card className={[classes.rowItemLeft, classes.rowItemRight, classes.newMessageCard].filter(x => x).join(' ')}>
        <div className={[classes.defaultLineCard].filter(x => x).join(' ')}></div>
        {getIcon(DEFAULT_ICON_STATUS)}
        <img className={classes.userAvatar} src={getUserAvatar()} alt="Profile Avatar" />
        <CardContent className={classes.cardContentContainer}>
          <CardContent className={classes.cardContentHeading}>
            <Typography className={classes.cardMainTitle}>
              <span>{'Nova mensagem'}</span>
              <span>{getFileNameShort() || 'Nenhum anexo.'}</span>
            </Typography>
          </CardContent>
          <CardContent className={classes.cardContentBody}>
            <div className={classes.cardMessageContainer}>
              <div className={classes.cardText}>
                {renderCardInputText()}
              </div>
            </div>
          </CardContent>
          <CardContent className={classes.cardContentButtons}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDropzoneDialog(true)}>
              <AttachFile className={classes.buttonIcon} />
              {'Anexar'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!newMessage.length ? true : false}
              onClick={() => onSaveNewMessage()}>
              <SaveIcon className={classes.buttonIcon} />
              {'Salvar'}
            </Button>
          </CardContent>
        </CardContent>
      </Card>
    )
  }

  const onDropFiles = (acceptedFiles) => {
    if (acceptedFiles) {
      handleFileUpload(acceptedFiles)
    }
  }

  const handleDisagreeUpload = () => {
    handleFileUpload(undefined)
    handleDropzoneDialog(false)
  }

  const getFileName = () => {
    let fileName
    if (fileUploaded) {
      fileUploaded.map(file => fileName = file.name || undefined)
    }
    return fileName
  }

  const getFileNameShort = () => {
    const fileName = getFileName()
    if (!fileName) {
      return undefined
    }
    if (fileName.length > 30) {
      return `${fileName.slice(0, 22)}...${fileName.slice(fileName.length - 5, fileName.length)}`
    }
    return fileName
  }

  const getHeaderFields = () => {
    if (!activeOS) {
      return undefined
    }
    const headerFields = []

    if (activeOS.invoice_entrance_number)
      headerFields.push({ name: 'Nota fiscal de entrada', value: activeOS.invoice_entrance_number })

    if (activeOS.invoice_return_number)
      headerFields.push({ name: 'Nota fiscal de saída', value: activeOS.invoice_return_number })

    if (activeOS.proposal_number)
      headerFields.push({ name: 'Proposta', value: activeOS.proposal_number })

    if (activeOS.proposal_version)
      headerFields.push({ name: 'Revisão', value: activeOS.proposal_version })

    if (activeOS.proposal_value_parts)
      headerFields.push({ name: 'Peças', value: formatCurrency(activeOS.proposal_value_parts) })

    if (activeOS.proposal_value_plates)
      headerFields.push({ name: 'Chapas', value: formatCurrency(activeOS.proposal_value_plates) })

    if (activeOS.proposal_value_services)
      headerFields.push({ name: 'Serviços', value: formatCurrency(activeOS.proposal_value_services) })

    if (activeOS.proposal_value_evaluation)
      headerFields.push({ name: 'Peritagem', value: formatCurrency(activeOS.proposal_value_evaluation) })

    if (activeOS.proposal_value_total)
      headerFields.push({ name: 'Total', value: formatCurrency(activeOS.proposal_value_total) })

    return headerFields;
  }

  const renderExpansionPanel = () => {
    return (
      <ExpansionPanel className={classes.expansionPanel}>
        {renderExpansionPanelSummary()}
        {renderExpansionPanelDetails()}
      </ExpansionPanel>
    )
  }

  const renderExpansionPanelSummary = () => {
    return (
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Link
          className={classes.buttonContainerLink}
          to='/servicos'>
          <ArrowBack />
        </Link>
        <div className={classes.expansionPanelContent}>
          <div className={classes.expansionPanelContentLeft}>{`${getDescription(props.match)}`}</div>
          <div className={classes.expansionPanelContentRight}>{'Mais informações'}</div>
        </div>
      </ExpansionPanelSummary>
    )
  }

  const renderExpansionPanelDetails = () => {
    const headerFields = getHeaderFields()
    if (!headerFields) {
      return undefined
    }
    return (
      <ExpansionPanelDetails
        classes={{
          root: classes.expansionPanelDetails
        }}>
        {headerFields.map((field, index) => {
          return (
            <div key={index}>
              <span><b>{`${field.name}: `}</b>{field.value}</span>
            </div>
          )
        })}
      </ExpansionPanelDetails>
    )
  }

  return (
    <BasePage activeRoute={props.location.pathname}>
      <div className={classes.content}>
        <Grid className={classes.titleContainer}>
          <h2>Gestão de Componentes</h2>
        </Grid>
        <Paper className={classes.container}>
          <div className={classes.heading}>
            {renderExpansionPanel()}
          </div>
          {!dataServiceOrderHistory && isResultsLoading && <LoadingLabel />}
          {!dataServiceOrderHistory && !isResultsLoading && emptyResult()}
          <div id={DEFAULT_TIMELINE_CONTENT_ID} className={classes.historyContent}>
            {!isResultsLoading && dataServiceOrderHistory && dataServiceOrderHistory.map((service, i) => renderCard(service, i))}
            {!isResultsLoading && dataServiceOrderHistory && cardNewMessage()}
            {dataServiceOrderHistory && <div id={DEFAULT_PAGINATION_TEXT_ID} className={classes.paginationContainer}>{'Fim das mensagens.'}</div>}
          </div>
        </Paper>
        <AlertDialog
          open={isDropzoneDialogOpen}
          handleDisagree={() => handleDisagreeUpload(false)}
          handleAgree={() => handleDropzoneDialog(false)}
          title={'Anexar arquivo'}
          agreeText={'Continuar'}
          disagreeText={'Cancelar'}>
          <Dropzone
            fileName={getFileNameShort()}
            onDropFiles={(acceptedFiles) => onDropFiles(acceptedFiles)} />
        </AlertDialog>
      </div>
    </BasePage>
  )
}
