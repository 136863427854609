import React from 'react'
import { formatDate, formatCurrency } from '@helpers/'
import Doc from '@material-ui/icons/DescriptionOutlined'
import moment from 'moment'

const STATUS = {
  CREATED: "created",
  WAITING_PROPOSAL: "waiting_proposal",
  WAITING_APPROVAL: "waiting_approval",
  WAITING_EXECUTION: "waiting_execution",
  IN_EXECUTION: "in_execution",
  WAITING_EXECUTION_BILLING: "waiting_execution_billing",
  WAITING_EXECUTION_SHIPMENT: "waiting_execution_shipment",
  EXECUTION_COMPLETED: "execution_completed"
}

export const statusFilterSteps = [
  { key: 'created', text: 'Em desmontagem' },
  { key: 'waiting_proposal', text: 'Em orçamento' },
  { key: 'waiting_approval', text: 'Aguardando aprovação' },
  { key: 'waiting_execution', text: 'Programando reforma' },
  { key: 'in_execution', text: 'Em reforma' },
  { key: 'waiting_execution_billing', text: 'Aguardando faturamento' },
  { key: 'waiting_execution_shipment', text: 'Aguardando coleta' },
  { key: 'execution_completed', text: 'Concluído' }
]

export const statusStep = {
  created: 'proposta',
  waiting_proposal: 'proposta',
  waiting_approval: 'aprovação',
  waiting_execution: 'execução',
  in_execution: 'execução',
  waiting_execution_billing: 'faturamento',
  waiting_execution_shipment: 'coleta',
  execution_completed: 'coleta',
}

export const chartStatus = {
  created: 'OS Criada',
  waiting_proposal: 'Aguardando proposta',
  waiting_approval: 'Aguardando aprovação',
  waiting_execution: 'Em programação',
  in_execution: 'Em execução',
  waiting_execution_shipment: 'Aguardando coleta',
  waiting_execution_billing: 'Aguardando faturamento',
  execution_completed: 'Concluida',
}

export const steps = [
  'ordem de serviço',
  'recebimento',
  'proposta',
  'aprovação',
  'execução',
  'faturamento',
  'coleta',
]

export const getStepLabels = (data) => {
  switch (data.last_status) {
    default:
    case STATUS.CREATED:
      return [getDescription(data), getServiceOrderLabel(data), getProposalLabel(data), '', '', '', '']
    case STATUS.WAITING_PROPOSAL:
      return [getDescription(data), getServiceOrderLabel(data), getProposalLabel(data), '', '', '', '']
    case STATUS.WAITING_APPROVAL:
      return [getDescription(data), getServiceOrderLabel(data), getProposalLabel(data), getApprovalLabel(data), '', '', '']
    case STATUS.WAITING_EXECUTION:
      return [getDescription(data), getServiceOrderLabel(data), getProposalLabel(data), getApprovalLabel(data), getExecutionLabel(data), '', '']
    case STATUS.IN_EXECUTION:
      return [getDescription(data), getServiceOrderLabel(data), getProposalLabel(data), getApprovalLabel(data), getExecutionLabel(data), '', '']
    case STATUS.WAITING_EXECUTION_BILLING:
      return [getDescription(data), getServiceOrderLabel(data), getProposalLabel(data), getApprovalLabel(data), getExecutionLabel(data), getBillingLabel(data), '']
    case STATUS.WAITING_EXECUTION_SHIPMENT:
      return [getDescription(data), getServiceOrderLabel(data), getProposalLabel(data), getApprovalLabel(data), getExecutionLabel(data), getBillingLabel(data), getShippingLabel(data)]
    case STATUS.EXECUTION_COMPLETED:
      return [getDescription(data), getServiceOrderLabel(data), getProposalLabel(data), getApprovalLabel(data), getExecutionLabel(data), getBillingLabel(data), getShippingLabel(data)]
  }
}

const getDescription = (data) => {
  if (!data || !data.description) {
    return ''
  }

  const osData = getOSData(data)
  return [`${data.description}`, osData]
}

const getOSData = (data) => {
  const code = data.code ? ['OS', data.code] : ['OS', " - "]
  const nf = data.invoice_entrance_number ? ['NF', data.invoice_entrance_number] : ['NF', ' - ']
  const location = data.location_slug ? ['Unidade', data.location_slug] : ['Unidade', ' - ']
  return [code, nf, location]
}

const getServiceOrderLabel = (data) => {
  if (!data || !data.service_order_creation_date) {
    return [['Chegada do componente', " - "]]
  }
  return [['Chegada do componente', formatDate(data.service_order_creation_date) || " - "]]
}

const getProposalLabel = (data) => {
  if (!data) {
    return ''
  }

  if (data.last_status === STATUS.CREATED) {
    return [['Previsão', 'Em programação']]
  }

  const planned_date = data.proposal_date_planned
  const replanned_date = data.proposal_date_replanned
  const final_date = data.proposal_date_real
  const total_value = data.proposal_value_total

  if(!replanned_date)
  {
    if (final_date && total_value > 0.0)
    return [
      ['Previsão Inicial', formatDate(planned_date) || ' - '],
      ['Envio', formatDate(final_date) || ' - '],
      ['Valor', formatCurrency(total_value) || ' - ']
    ]
    
    if (final_date)
    return [
      ['Previsão Inicial', formatDate(planned_date) || ' - '],
      ['Envio', formatDate(final_date) || ' - ']
    ]
  }

  if(replanned_date)
  {
    if (final_date && total_value > 0.0)
    return [
      ['Previsão Atualizada', formatDate(replanned_date) || ' - '],
      ['Envio', formatDate(final_date) || ' - '],
      ['Valor', formatCurrency(total_value) || ' - ']
    ]
    
    if (final_date)
    return [
      ['Previsão Inicial', formatDate(planned_date) || ' - '],
      ['Previsão Atualizada', formatDate(replanned_date) || ' - '],      
      ['Envio', formatDate(final_date) || ' - '],
    ]
  } 

  // if (!final_date)
  return [['Previsão', formatDate(planned_date) || ' - ']]
}

const getApprovalLabel = (data) => {
  if (!data) {
    return ''
  }

  const proposalNumber = getDataField(data, 'proposal_number', <Doc />)
  if (data.last_status === STATUS.WAITING_APPROVAL) {
    return [
      proposalNumber,
      ['Aprovação', 'Pendente']
    ]
  }
  return [
    proposalNumber,
    ['Aprovação', formatDate(data.proposal_response_date_real) || " - "]
  ]
}

const getExecutionLabel = (data) => {
  if (!data || !data.last_status) {
    return ''
  }

  if (data.last_status === STATUS.WAITING_EXECUTION) {
    return [['Previsão', 'Em programação']]
  }

  const planned_date = data.execution_date_planned
  const replanned_date = data.execution_date_replanned
  const final_date = data.execution_date_real

  if (replanned_date) {
    return [
      ['Previsão Inicial', formatDate(planned_date) || ' - '],
      ['Previsão Atualizada', formatDate(replanned_date) || " - "],
      ['Execução Final', formatDate(final_date) || 'Executando']
    ]
  }
  else {
    return [
      ['Previsão', formatDate(planned_date) || ' - '],
      ['Execução', formatDate(final_date) || 'Executando']
    ]
  }

}

const getShippingLabel = (data) => {
  if (!data || !data.shipping_date_real) {
    return [['Coleta', 'Aguardando coleta']]
  }
  return [['Coleta', formatDate(data.shipping_date_real)]]
}

const getBillingLabel = (data) => {
  if (!data) {
    return ''
  }
  if (data.last_status === STATUS.WAITING_EXECUTION_BILLING) {
    return [['Faturamento', 'Em análise']]
  }

  const invoiceNumber = getDataField(data, 'invoice_return_number', <Doc />)
  if (data.invoice_return_number) {
    return [['Faturamento', 'Realizado'], invoiceNumber]
  }
  return [['Faturamento', 'Pendente']]
}

const getDataField = (data, field, text) => {
  if (data[field]) {
    return [text, data[field]]
  }
}

export const getLeadTime = (data) => {
  const leadTime = [
    null,
    null,
    getProposalLeadTime(data),
    getApprovalLeadTime(data),
    getExecutionLeadTime(data),
    null,
    getCompletionLeadTime(data)
  ]
  return leadTime
}

const getProposalLeadTime = (data) => {
  if (!data || !data.service_order_creation_date || !data.proposal_date_real) {
    return null
  }
  const created = moment(data.service_order_creation_date)
  const proposal = moment(data.proposal_date_real)
  return proposal.diff(created, 'days')
}

const getApprovalLeadTime = (data) => {
  if (!data || !data.proposal_date_real || !data.proposal_response_date_real) {
    return null
  }
  const proposal = moment(data.proposal_date_real)
  const approval = moment(data.proposal_response_date_real)
  return approval.diff(proposal, 'days')
}

const getExecutionLeadTime = (data) => {
  if (!data || !data.proposal_response_date_real || !data.execution_date_real) {
    return null
  }
  const approval = moment(data.proposal_response_date_real)
  const execution = moment(data.execution_date_real)
  return execution.diff(approval, 'days')
}

const getCompletionLeadTime = (data) => {
  if (!data || !data.service_order_creation_date || !data.shipping_date_real) {
    return null
  }
  const created = moment(data.service_order_creation_date)
  const shipping = moment(data.shipping_date_real)
  return shipping.diff(created, 'days')
}