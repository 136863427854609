import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  active: {
    color: theme.healthy,
    backgroundColor: 'white'
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  completed: {
    color: theme.healthy,
    zIndex: 1,
    fontSize: 20,
    backgroundColor: 'white'
  },
  waiting: {
    color: theme.fastService,
    zIndex: 1,
    fontSize: 20,
    backgroundColor: 'white'
  },
  error: {
    color: theme.rebuild,
    zIndex: 1,
    fontSize: 20,
    backgroundColor: 'white'
  },
  leadTimeContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    margin: '5px',
    textTransform: 'capitalize',
    '& span': {
      margin: '2.5px 0',
      '&:first-child': {
        fontWeight: 'bold'
      }
    }
  },
  tooltip: {
    zIndex: '1 !important',
  }
}))

export default styles
