import React from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import Home from '@pages/home'
import Servicos from '@pages/servicos'
import Detalhes from '@pages/service-timeline'
import Dashboard from '@pages/dashboard'
import { useStateValue } from '@state'

export default function App (props) {
  const [{ userData }] = useStateValue()
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Home} />
        <PrivateRoute userData={userData} path='/dashboard' component={Dashboard} />
        <PrivateRoute userData={userData} path='/servicos' component={Servicos} />
        <PrivateRoute userData={userData} path='/detalhes/:id/:description' component={Detalhes} exact={false} />
        <PrivateRoute userData={userData} component={Home} />
      </Switch>
    </Router>
  )
}

function PrivateRoute ({ userData, path, component, exact }) {
  if (userData && userData.name && userData.headers) {
    return <Route exact={exact || true} path={path} component={component} />
  }
  return <Redirect push to='/' />
}
