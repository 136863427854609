import React, { useState } from 'react'
import LoginForm from './login-form'
import useStyles from './styles'
import axios from 'axios'
import { useStateValue } from '../../state'
import Logo from '@imgs/logo_dark.png'

export default function Home(props) {
  const [{ userData }, dispatch] = useStateValue()
  const [isLoading, setLoading] = useState(undefined)
  const [loginMessage, setLoginMessage] = useState(undefined)
  const classes = useStyles()
  const dispatchLogin = payload => dispatch({ type: 'login', payload })
  const onSubmitLoginForm = (email, password) => {
    if (!email || !password || isLoading) {
      return
    }
    setLoading(true)
    setLoginMessage(undefined)
    axios
      .post(`${process.env.REACT_APP_DEFAULT_API}/login`, {
        user: {
          email,
          password
        }
      })
      .then(response => {
        const payload = {
          ...response.data,
          headers: response.headers
        }
        dispatchLogin(payload)
        setLoading(false)
        props.history.push('/servicos', { payload })
      })
      .catch(error => {
        setLoading(false)
        if (error.response && error.response.data) {
          setLoginMessage(error.response.data)
        }
      })
  }
  return (
    <div className={classes.loginContainer}>
      <div className={classes.loginLeftSide} />
      <div className={classes.loginRightSide}>
        <img src={Logo} alt='Logo' />
        <LoginForm isLoading={isLoading} onSubmitForm={onSubmitLoginForm} loginMessage={loginMessage} />
      </div>
    </div>
  )
}
