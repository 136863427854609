import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    '& h2': {
      color: theme.white,
      fontWeight: 'normal',
      fontSize: '32px',
      margin: '0 0 20px 0',
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 0px',
    position: 'fixed',
    top: '120px',
    width: 'calc(100% - 215px)',
    height: '100%',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    top: '45px',
    zIndex: 1100,
    width: 'calc(100% - 215px)',
  },
}))

export default styles
