import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  root: {
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    width: 120,
    height: '30px'
  },
  inputContainer: {
    flex: 1,
    color: 'theme.tertiaryTextColor',
    fontSize: 9
  },
  form: {
    display: 'flex',
    width: '100%'
  },
  iconButton: {
    padding: '5px !important'
  }
}))

export default styles
