import { withStyles } from '@material-ui/core/styles'
import StepConnector from '@material-ui/core/StepConnector'

export const QontoConnectorDefault = withStyles(theme => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 5px)',
    right: 'calc(50% + 5px)'
  },
  active: {
    '& $line': {
      border: 'none !important',
      height: '2px',
      background: theme.healthy
    }
  },
  completed: {
    '& $line': {
      border: 'none !important',
      height: '2px',
      background: theme.healthy
    }
  },
  line: {
    border: 'none !important',
    background: '#eaeaf0',
    height: '2px'
  }
}))(StepConnector)

export const QontoConnectorWaiting = withStyles(theme => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 5px)',
    right: 'calc(50% + 5px)'
  },
  active: {
    '& $line': {
      border: 'none !important',
      height: '2px',
      background: theme.fastService
    }
  },
  completed: {
    '& $line': {
      border: 'none !important',
      height: '2px',
      background: theme.fastService
    }
  },
  line: {
    background: '#eaeaf0',
    height: '2px'
  }
}))(StepConnector)
