import moment from 'moment'

export function formatDate(date) {
  if (!date) {
    return
  }
  return moment(date).format('DD/MM/YY')
}

export function formatDateLong(date) {
  if (!date) {
    return
  }
  return moment(date).format('DD/MM/YYYY')
}

export function formatDateWithHour(date) {
  if (!date) {
    return
  }
  return moment(date).format('DD/MM H:mm')
}

export function formatCurrency(value) {
  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  return formatter.format(value)
}