import React, { useState } from 'react'
import axios from 'axios'
import contentDisposition from 'content-disposition'
import { useStateValue } from '../../../state'

import useStyles from './styles'

import LinearProgress from '@material-ui/core/LinearProgress'
import AlertDialog from '@layouts/dialog'
const DEFAULT_EXPORT_TEXT = 'Clique em Exportar para gerar o relatório ou em Cancelar para sair da tela.'
const DEFAULT_EXPORTING_TEXT = 'Exportando relatório'


export default function DashboardDialog({ isDashboardDialogOpen, handleDashboardDialog }) {
  const classes = useStyles()
  const [{ userData }] = useStateValue()
  const [isExportingDashboard, handleExportingDashboard] = useState(false)

  const getDialogContent = () => {
    if (!isExportingDashboard) {
      return DEFAULT_EXPORT_TEXT
    }
    return (
      <div className={classes.root}>
        <span>{DEFAULT_EXPORTING_TEXT}</span>
        <LinearProgress />
      </div>
    )
  }

  const createDownloadLink = (data, fileName) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', fileName)
    document.body.appendChild(link)

    return link
  }

  const fetchReport = () => {
    const headers = userData && userData.headers ? userData.headers : undefined
    axios
      .get(`${process.env.REACT_APP_DEFAULT_API}/service_orders/export_csv`, {
        headers: { ...headers },
        responseType: 'blob'
      })
      .then(response => {
        const { data, headers } = response

        const disposition = contentDisposition.parse(headers["content-disposition"])
        const fileName = disposition.parameters.filename
        const link = createDownloadLink(data, fileName)

        link.click()
        link.remove()
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <AlertDialog
      open={isDashboardDialogOpen}
      handleDisagree={() => {
        handleExportingDashboard(false)
        handleDashboardDialog(false)
      }}
      handleAgree={() => {
        if (!isExportingDashboard) {
          handleExportingDashboard(true)
          fetchReport()
          setTimeout(() => {
            handleDashboardDialog(false)
          }, 2000);
        }
      }}
      title={'Deseja exportar relatorio de OS?'}
      agreeText={'Exportar'}
      disagreeText={'Cancelar'}>
      {getDialogContent()}
    </AlertDialog>
  )
}